import './App.css';
import foto from './profile.png'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Home from './Home.js';
import Summary from './Summary.js';
import './dropdown.css';
import AboutMe from './AboutMe';
import Research2 from './research2';

import {Routes, Route, useLocation} from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';




    function App() {

      const location = useLocation();

      document.title = "Julian Pasveer";
      return (
        <><link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"></link><Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
            {/* <img  class="imagetest" src={foto} height="75px"></img> */}
            <Navbar.Brand href="/home">Home</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto">
                <a style={{ textDecoration: "none" }} href="/about">
                  <div className="button-primary">About Me </div>
                </a>
                <div>/</div>
                {/* <a style={{ textDecoration: "none"}} href="/">
                  <div className='button-primary'>/</div>
                </a> */}
                <a style={{ textDecoration: "none"}} href="/research">
                  <div className='button-primary'> Research Papers </div>
                </a>
                <div>/</div>
                {/* <a style={{ textDecoration: "none"}} href="/">
                  <div className='button-primary'>/</div>
                </a> */}
                <a style={{ textDecoration: "none" }} href="/summaries">
                  <div className="button-primary">Summaries</div>
                </a>
                <div>/</div>
                {/* <div class="dropdown">
                <button class="button4-primary">More ↓</button>
                  <div class="dropdown-content">
                      <a href="#">Link 1</a>
                      <a href="#">Link 2</a>
                      <a href="#">Link 3</a>
                  </div>
                </div> */}
                
              </Nav>
              <Nav>
                <a href="https://www.linkedin.com/in/julian-pasveer-466ba125a/" target="_blank">
                  <center><img  class="imagetest4" src="https://www.citypng.com/public/uploads/preview/linkedin-square-white-icon-transparent-png-11640440452zi2ykndpw2.png" height="35px">
                    </img></center></a>
                <div>
                  ///
                </div>
                <a href="https://www.instagram.com/julian_pasveer/" target="_blank">
                  <center><img  class="imagetest3" src="https://www.edigitalagency.com.au/wp-content/uploads/new-Instagram-logo-white-glyph-1200x1199.png" height="35px">
                    </img></center></a>
                <div>
                  ///
                </div>
                <a href="https://github.com/JGTPasveer" target="_blank">
                  <center><img  class="imagetest2" src="https://img.icons8.com/ios-glyphs/256/github.png" height="35px"></img></center>
                  </a>
                <div>//</div>

              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <AnimatePresence mode="wait" initial="false">
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path='/home' element={<Home />} />
          <Route path='/summaries' element={<Summary />} />
          <Route path='/about' element={<AboutMe />} />
          <Route path='/research' element={<Research2 />} />
        </Routes>
      </AnimatePresence>
      <footer>
        <center className='foot'>Last update: 12-03-2024</center>
      </footer>
        {/* <Routes>
                <Route path="/"                         element={<Home />}>  </Route>
                <Route path="/home"                         element={<Home />}>  </Route>
                <Route path="summaries"                     element={<Summary />}></Route>
                <Route path="about"                     element={<AboutMe />}></Route>
                <Route path="research-papers"                     element={<Research />}></Route>
        </Routes> */}
        </>
        
      );
    }
    
    export default App;